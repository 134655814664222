
import { Switch } from 'react-router-dom';
import Route from './Route';

import Site from '../pages/Site';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';

import Dashboard from '../pages/Dashboard';
import Config from '../pages/Config';
import Configlista from '../pages/Configlistar';
import Projetos from '../pages/Projetos';
import Profile from '../pages/Profile';
import Textos from '../pages/Textos';
import Textoslista from '../pages/Textoslistar';
import Produtores from '../pages/Produtores';
import Produtoreslista from '../pages/Produtoreslistar';
export default function Routes(){
  return(
    <Switch>
      <Route exact path="/" component={Site} />
      <Route exact path="/login" component={SignIn} />
      <Route exact path="/register" component={SignUp} />

      <Route exact path="/dashboard" component={Dashboard} isPrivate />
      <Route exact path="/profile" component={Profile} isPrivate />

      <Route exact path="/config/:id" component={Config} isPrivate />
      <Route exact path="/configlistar" component={Configlista} isPrivate />
      <Route exact path="/projetos" component={Projetos} isPrivate />
      <Route exact path="/textos" component={Textos} isPrivate />
      <Route exact path="/textos/:id" component={Textos} isPrivate />
      <Route exact path="/textoslistar" component={Textoslista} isPrivate />
      <Route exact path="/produtores" component={Produtores} isPrivate />
      <Route exact path="/produtores/:id" component={Produtores} isPrivate />
      <Route exact path="/produtoreslistar" component={Produtoreslista} isPrivate />
      
    </Switch>
  )
}