
import { useContext } from 'react';
import './header.css';
import { AuthContext } from '../../contexts/auth';
import avatar from '../../assets/avatar.png';

import { Link } from 'react-router-dom';
import { FiHome, FiUser, FiSettings, FiMapPin, FiSliders, FiType, FiCoffee, FiBook, FiGlobe, FiGrid} from "react-icons/fi";


export default function Header(){
  const { user, signOut } = useContext(AuthContext);


  return(
    <div className="sidebar">
      <div>
        <img src={user.avatarUrl === null ? avatar : user.avatarUrl } alt="Foto avatar" />
      </div>

      <Link to="/dashboard">
        <FiHome color="#FFF" size={24} />
        Início
      </Link>

      <Link to="/profile">
        <FiSettings color="#FFF" size={24} />
        Configurações Usuário
      </Link>
      <Link to="/configlistar">
        <FiSliders color="#FFF" size={24} />
        Configurações Página
      </Link>
      <Link to="/projetos">
        <FiUser color="#FFF" size={24} />
        Projetos
      </Link>
      <Link to="/textoslistar">
        <FiType color="#FFF" size={24} />
        Textos
      </Link>  
      <Link to="/produtoreslistar">
        <FiMapPin color="#FFF" size={24} />
        Produtores
      </Link>
            <button className="logout-btn" onClick={ () => signOut() } >
               Sair
            </button>
    </div>
  )
}