
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './textos.css';
import { FiPlusCircle } from 'react-icons/fi'

export default function Textos(){
  const { id } = useParams();
  const history = useHistory();

  const [loadProjetos, setLoadProjetos] = useState(true);
  const [projetos, setProjetos] = useState([]);
  const [projetosSelected, setProjetosSelected] = useState(0);

  const [assunto, setAssunto] = useState('');
  const [status, setStatus] = useState('');
  const [complemento, setComplemento] = useState('');
  const [texto1, setTexto1] = useState('');
  const [texto2, setTexto2] = useState('');
  const [texto3, setTexto3] = useState('');
  const [texto4, setTexto4] = useState('');
  const [texto5, setTexto5] = useState('');
  const [texto6, setTexto6] = useState('');


  const [idProjeto, setIdProjeto] = useState(false);

  const { user } = useContext(AuthContext);


  useEffect(()=> {
    async function loadProjetos(){
      await firebase.firestore().collection('projetos')
      .get()
      .then((snapshot)=>{
        let lista = [];

        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomeProjeto: doc.data().nomeProjeto
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA PROJETO ENCONTRADO');
          setProjetos([ { id: '1', nomeProjeto: 'AVULSO' } ]);
          setLoadProjetos(false);
          return;
        }

        setProjetos(lista);
        setLoadProjetos(false);

        if(id){
          loadId(lista);
        }

      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadProjetos(false);
        setProjetos([ { id: '1', nomeProjeto: '' } ]);
      })

    }

    loadProjetos();

  }, [id]);



  async function loadId(lista){
    await firebase.firestore().collection('textos').doc(id)
    .get()
    .then((snapshot) => {
      setAssunto(snapshot.data().assunto);
      setStatus(snapshot.data().status);
      setComplemento(snapshot.data().complemento);
      setTexto1(snapshot.data().texto1);
      setTexto2(snapshot.data().texto2);
      setTexto3(snapshot.data().texto3);
      setTexto4(snapshot.data().texto4);
      setTexto5(snapshot.data().texto5);
      setTexto6(snapshot.data().texto6)

      let index = lista.findIndex(item => item.id === snapshot.data().projetoId );
      setProjetosSelected(index);
      setIdProjeto(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdProjeto(false);
    })
  }

  async function handleRegister(e){
    e.preventDefault();

    if(idProjeto){
      await firebase.firestore().collection('textos')
      .doc(id)
      .update({
        projeto: projetos[projetosSelected].nomeProjeto,
        projetoId: projetos[projetosSelected].id,
        assunto: assunto,
        status: status,
        complemento: complemento,
        texto1: texto1,
        texto2: texto2,
        texto3: texto3,
        texto4: texto4,
        texto5: texto5,
        texto6: texto6,
        userId: user.uid
      })
      .then(()=>{
        toast.success('Texto Editado com sucesso!');
        setProjetosSelected(0);
        setComplemento('');
        setTexto1('');
        setTexto2('');
        setTexto3('');
        setTexto4('');
        setTexto5('');
        setTexto6('');
        history.push('/dashboard');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

      return;
    }

    await firebase.firestore().collection('textos')
    .add({
      created: new Date(),
      projeto: projetos[projetosSelected].nomeProjeto,
      projetoId: projetos[projetosSelected].id,
      assunto: assunto,
      status: status,
      complemento: complemento,
      texto1: texto1,
      texto2: texto2,
      texto3: texto3,
      texto4: texto4,
      texto5: texto5,
      texto6: texto6,
      userId: user.uid
    })
    .then(()=> {
      toast.success('Texto criado com sucesso!');
      setComplemento('');
      setProjetosSelected(0);
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })


  }


  //Chamado quando troca o assunto
  function handleChangeSelect(e){
    setAssunto(e.target.value);
  }


  //Chamado quando troca o status
  function handleOptionChange(e){
    setStatus(e.target.value);
  }

  //Chamado quando troca de cliente
  function handleChangeProjetos(e){
    //console.log('INDEX DO CLIENTE SELECIONADO: ', e.target.value);
    //console.log('Cliente selecionado ', customers[e.target.value])
    setProjetosSelected(e.target.value);
  }

  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Novo texto">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >
            
            <label>Projetos</label>

            {loadProjetos ? (
              <input type="text" disabled={true} value="Carregando projetos..." />
            ) : (
                <select value={projetosSelected} onChange={handleChangeProjetos} >
                {projetos.map((item, index) => {
                  return(
                    <option key={item.id} value={index} >
                      {item.nomeProjeto}
                    </option>
                  )
                })}
              </select>
            )}

            <label>Assunto</label>
            <select value={assunto} onChange={handleChangeSelect}>
              <option value="TextoPatrimonioMineiro">Texto Patrimônio Mineiro</option>
              <option value="Outro">Outro</option>
            </select>

            <label>Status</label>
            <div className="status">
              <input 
              type="radio"
              name="radio"
              value="Web"
              onChange={handleOptionChange}
              checked={ status === 'Web' }
              />
              <span>Web</span>

              <input 
              type="radio"
              name="radio"
              value="App"
              onChange={handleOptionChange}
              checked={ status === 'App' }
              />
              <span>App</span>

              <input 
              type="radio"
              name="radio"
              value="Ambos"
              onChange={handleOptionChange}
              checked={ status === 'Ambos' }
              />
              <span>Ambos</span>
            </div>

            <label>Complemento</label>
            <textarea
              type="text"
              placeholder="Coloque aqui seu texto"
              value={complemento}
              onChange={ (e) => setComplemento(e.target.value) }
            />
            <label>Texto 1</label>
            <textarea
              type="text"
              placeholder="Coloque aqui seu texto"
              value={texto1}
              onChange={ (e) => setTexto1(e.target.value) }
            />
            <label>Texto 2</label>
            <textarea
              type="text"
              placeholder="Coloque aqui seu texto"
              value={texto2}
              onChange={ (e) => setTexto2(e.target.value) }
            />
            <label>Texto 3</label>
            <textarea
              type="text"
              placeholder="Coloque aqui seu texto"
              value={texto3}
              onChange={ (e) => setTexto3(e.target.value) }
            />
            <label>Texto 4</label>
            <textarea
              type="text"
              placeholder="Coloque aqui seu texto"
              value={texto4}
              onChange={ (e) => setTexto4(e.target.value) }
            />
            <label>Texto 5</label>
            <textarea
              type="text"
              placeholder="Coloque aqui seu texto"
              value={texto5}
              onChange={ (e) => setTexto5(e.target.value) }
            />
            <label>Texto 6</label>
            <textarea
              type="text"
              placeholder="Coloque aqui seu texto"
              value={texto6}
              onChange={ (e) => setTexto6(e.target.value) }
            />
            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>
    </div>
  )
}