import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

let firebaseConfig = {
  apiKey: "AIzaSyBZjXyKXFmr0-q4E4Jv1pSbyY3k-LNoj4w",
  authDomain: "festin-bc42f.firebaseapp.com",
  databaseURL: "https://festin-bc42f-default-rtdb.firebaseio.com",
  projectId: "festin-bc42f",
  storageBucket: "festin-bc42f.appspot.com",
  messagingSenderId: "887172980994",
  appId: "1:887172980994:web:fa0ba6c2d68ab01e28e21b",
  measurementId: "G-PLP55MYM78"
};

if(!firebase.apps.length){
  firebase.initializeApp(firebaseConfig);
}

export default firebase;