
import './site.css';
import { useState, useEffect } from 'react';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { FiMessageSquare, FiPlus, FiSearch, FiEdit2, FiSettings } from 'react-icons/fi';
import { FaFacebookSquare, FaInstagram, FaWhatsappSquare, FaYoutube, FaApple, FaAndroid, FaLaptop } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import firebase from '../../services/firebaseConnection';
import Modal from '../../components/Modal';

import logo from '../imagens/logo.png';
import logo4 from '../imagens/logo4.png';

import cortinad from '../imagens/fundo_miolod.png';
import cortinae from '../imagens/fundo_mioloe.png';
import rodapeimg from '../imagens/rodape.png';

import tinzinho from '../imagens/tinzinhobracosabertos.png';

export default function Site(){

    return(
      <div className="App">
      <div className="Menu">
      &nbsp;&nbsp;&nbsp;
        Seja bem vindo! Dia 01 a 06 de Junho 2021 - nos apps e nas redes sociais!&nbsp;&nbsp;&nbsp;
        <a href={"https://www.facebook.com/festinbrasil"} target="_blank"><FaFacebookSquare /></a>&nbsp;&nbsp;&nbsp;
        <a href={"https://www.instagram.com/festinbrasil/"} target="_blank"><FaInstagram /></a>&nbsp;&nbsp;&nbsp;
        <a href={"http://youtube.com/channel/UC_2XHfuMQpq-12nEYlvACQA"} target="_blank"><FaYoutube /></a>&nbsp;&nbsp;&nbsp;
        <a href={"https://apps.apple.com/us/app/festival-de-teatro-infantil/id1569048528"} target="_blank"><FaApple /></a>&nbsp;&nbsp;&nbsp;
        <a href={"https://play.google.com/store/apps/details?id=com.inovita.festin.br"} target="_blank"><FaAndroid /></a>&nbsp;&nbsp;&nbsp;
      </div>
      <div className="Topo">
          <img src={logo4} className="App-logo" alt="Festin - Festival de Teatro Infantil" />
      </div>
  
      <div className="Teatro" id="centro">
        <div className="Latesquerda">
          <img src={cortinae} className="Imgcortina"/>
        </div>
        <div className="Centro">
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <img src={tinzinho} className="Imgtinzinho"alt="Tinzinho" />
        </div>
        <div className="Centro">
        <br></br>
        ASSISTA TODAS APRESENTAÇÕES ESTÃO DISPONÍVEIS ATÉ 30 ABRIL 2022. BAIXE O GIBI NO FINAL DA PÁGINA!
        <br></br>
        As apresentações foram realizadas entre os dias 1º e 6 de junho 2021, agora todo conteúdo fica disponivel para as crianças acessarem de qualquer lugar com uma conexão com a Internet: basta acessar nossos canais no YouTube, Facebook e Instagram, ou baixar o aplicativo, criado exclusivamente para o evento.
        <br></br>
        <br></br>
          <a href={"https://youtu.be/88XZXBRVF5I"} target="_blank"><FaYoutube /></a>Achadouros - Teatro para Bebês - 1º de Junho
          <br></br><br></br>
          <a href={"https://youtu.be/jmq23_7hQDw"} target="_blank"><FaYoutube /></a>Show de Palhágica - 2 de Junho
          <br></br><br></br>
          <a href={"https://youtu.be/QGc1DF2gm8I"} target="_blank"><FaYoutube /></a>Um Golinho Só - 3 de Junho
          <br></br><br></br>
          <a href={"https://youtu.be/Njd27M93Iuo"} target="_blank"><FaYoutube /></a>Em Busca do Snark Invisível - 4 de Junho
          <br></br><br></br>
          <a href={"https://youtu.be/cwUFDIimT1k"} target="_blank"><FaYoutube /></a>Casulo - 5 de Junho
          <br></br><br></br>
          <a href={"https://youtu.be/hABEplbEYAc"} target="_blank"><FaYoutube /></a>Juvenal, Pita e o Velocípede - 5 de Junho
          <br></br><br></br>
          <a href={"https://youtu.be/gEtWJUe8NHY"} target="_blank"><FaYoutube /></a>Memórias de um Quintal - 6 de Junho
          <br></br>
          <br></br>
          Veja também nosso gibi e conheça o mundo do teatro junto com o Tinzinho. Baixe o aplicativo!
          <br></br>
          <a href={"https://firebasestorage.googleapis.com/v0/b/festin-bc42f.appspot.com/o/impressos%2FGibi2festinOk.pdf?alt=media&token=6ffc7b3d-3ba2-4bf4-9150-d0039923f3d5"} target="_blank">
          <img src={"https://firebasestorage.googleapis.com/v0/b/festin-bc42f.appspot.com/o/impressos%2Fgibi2.png?alt=media&token=7c32b23c-5588-49cc-b3fb-6fe5c2d427a9"} className="Imgibi"alt="GIBI 2016" /></a>
          <br></br> GIBI 2016 "O Tinzinho e o Teatro"
          <br></br>
          <a href={"https://firebasestorage.googleapis.com/v0/b/festin-bc42f.appspot.com/o/impressos%2FGibi4festinOk.pdf?alt=media&token=f9345de2-2cac-403a-9a55-c7db9bfa1c47"} target="_blank">
          <img src={"https://firebasestorage.googleapis.com/v0/b/festin-bc42f.appspot.com/o/impressos%2Fgibi4.png?alt=media&token=5ce601eb-13e9-4ee1-93aa-c30ec6ddac82"} className="Imgibi"alt="GIBI 2021" /></a>
          <br></br> GIBI 2021 "O Tinzinho e o Teatro na Pandemia"
          <br></br>
        </div>
        <div className="Latdireita">  
          <img src={cortinad} className="Imgcortina"/>
        </div>
      </div>

  
      <div className="Rodape">
          <img src={rodapeimg} className="Imgrodape"/>
      </div>

      <div className="Barrarodape">
      Todos os direitos reservados FESTIN - Festival de Teatro Infantil©    INOVITÀ©
      </div>
  
      </div>
  
  )
}